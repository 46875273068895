import { GlobalConfig } from "./config.interface";

export const segmentProxyUrl = "https://segment.dontdrivedirty.com";
export const segmentSnippetVersion = "4.15.3";

export const globalConfig: GlobalConfig = {
  findLocationHref: "https://www.dontdrivedirty.com",
  termsOfService: "https://www.dontdrivedirty.com/terms-of-service",
  termsOfUse: "https://www.dontdrivedirty.com/terms-of-use",
  privacyPolicy: "https://www.dontdrivedirty.com/privacy-policy",
  ccpa: "https://www.dontdrivedirty.com/privacy-policy#california",
  locations: "https://www.dontdrivedirty.com/locationsandpricing/",

  limits: {
    maxVehiclesOnAccount: 10,
    maxVehiclesOnSubscription: 5,
  },

  mobileBreakpoint: 768,

  supportNumber: "8887722792",
  supportEmail: "info@dontdrivedirty.com",
  refreshTokenKey: "rtk",

  pwaMode: "standalone",

  featureFlags: {},

  promos: {},
  consentManagerInput: {
    container: "#consent", // id of div that will be replaced by consent banner
    writeKey: null, // will override in service
    bannerHideCloseButton: false,
    bannerContent:
      "Quick Quack respects your privacy. We use cookies to enhance your experience on myQQ, and to help us improve. But if you would prefer not to share",
    bannerSubContent: "You can change your preferences at any time.",
    bannerBackgroundColor: "#f9f9f9",
    bannerTextColor: "#000000",
    preferencesDialogTitle: "myQQ privacy settings",
    preferencesDialogContent:
      "We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, and increase the overall performance of our site.",
    cancelDialogTitle: "Are you sure you want to cancel?",
    cancelDialogContent:
      "Your preferences have not been saved. By continuing to use myQQ, you are agreeing to our privacy policy",
    closeBehavior: "dismiss",
    defaultDestinationBehavior: "disable",
    implyConsentOnInteraction: false,
    shouldRequireConsent: () => true,
    cdnHost: "segment.dontdrivedirty.com",
    initialPreferences: {
      Analytics: true,
    },
    customCategories: {
      Analytics: {
        integrations: ["Actions Amplitude", "Mouseflow", "Facebook Pixel"],
        purpose:
          "Provide statistical information on site usage, e.g., web analytics so we can improve myQQ over time.",
      },
    },
  },
  consentManagerUrl:
    "https://unpkg.com/@segment/consent-manager@5.7.0/standalone/consent-manager.js",
  cdnHost: "https://cdn.dontdrivedirty.com/myqq",
};
