// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "857a4267fbd24b02de5f730d53f6b746f8630d31",
  version: "1.106.0",
};
